import React from 'react';
import { Typography, Grid, Icon } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import vtcNantes from "../vtcnantes.jpeg";
import ReservationButton from "../Components/ReservationButton";

// Import payment method logos
import VisaLogo from '../visa.png';
import MastercardLogo from '../mastercard.png';
import PaypalLogo from '../paypal.png';
import AmexLogo from '../amex.png';
import GpayLogo from '../gpay.png';
import ApplePayLogo from '../apay.png';

// Import necessary icons from '@mui/icons-material'
import PowerIcon from '@mui/icons-material/Power';
import LocalDrinkIcon from '@mui/icons-material/Liquor';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import StormIcon from '@mui/icons-material/Storm';

const useStyles = makeStyles((theme) => ({
    equipementItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '5px',
        textAlign: 'center',
        border: '0px',
        boxShadow: '0px ',
    },
    title: {
        fontWeight: 'bold',
    },
    centerItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        fontSize: 48,
        marginTop: '5px',
    },
    container: {
        marginTop: '20px',
        marginBottom: '30px',
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
    },
    image: {
        maxWidth: '100%', // Ensure the image doesn't exceed container's width
        width: '40%', // Adjusts to 80% for large screens
        height: 'auto',
        display: 'block',
        margin: 'auto',
    },
    paymentLogo: {
        maxWidth: '80px', // Adjust this size for mobile
        margin: '10px',
        width: '100%',
        height: 'auto',
    },
    paymentGridItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const EquipementItem = ({ title, icon }) => {
    const classes = useStyles();
    return (
        <Grid item xs={12} sm={6} md={3} lg={3}>
            <div className={`${classes.equipementItem} ${classes.centerItems}`}>
                <Icon className={classes.icon}>{icon}</Icon>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
            </div>
        </Grid>
    );
};

export function Services() {
    const classes = useStyles();

    const equipements = [
        { title: 'Chargeur', icon: <PowerIcon /> },
        { title: 'Rafraîchissement', icon: <LocalDrinkIcon /> },
        { title: 'Gel hydroalcoolique', icon: <SanitizerIcon /> },
        { title: 'Friandises', icon: <StormIcon /> },
    ];

    const paymentMethods = [
        { name: 'Visa', logo: VisaLogo },
        { name: 'Mastercard', logo: MastercardLogo },
        { name: 'PayPal', logo: PaypalLogo },
        { name: 'American Express', logo: AmexLogo },
        { name: 'Apple Pay', logo: ApplePayLogo },
        { name: 'Google Pay', logo: GpayLogo },
    ];

    return (
        <>
            <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '20px' }}>
                Les services de {process.env.REACT_APP_BUSINESS_NAME} Nantes
            </Typography>

            <div className={classes.imageContainer}>
                <Link to="/">
                    <img
                        src={vtcNantes}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </Link>
            </div>

            <Typography variant="body1" paragraph>
                Chez {process.env.REACT_APP_BUSINESS_NAME} à Nantes, nous garantissons une expérience exceptionnelle lors de chaque déplacement.
            </Typography>

            <Grid container spacing={2} className={classes.container}>
                {equipements.map((service, index) => (
                    <EquipementItem key={index} title={service.title} icon={service.icon} />
                ))}
            </Grid>

            <ReservationButton buttonText="Réserver" phoneNumber={process.env.REACT_APP_BUSINESS_PHONE} />

            <Typography variant="h5" style={{ textAlign: 'center', marginTop: '20px' }}>
                Moyens de paiement acceptés
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                {paymentMethods.map((method, index) => (
                    <Grid item xs={6} sm={4} md={3} key={index} className={classes.paymentGridItem}>
                        <img src={method.logo} alt={method.name} className={classes.paymentLogo} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

import React, { useLayoutEffect, useState, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import axios from 'axios';
import { Button, Typography, Grid, FormControl, Select, MenuItem, Box, Snackbar, SnackbarContent, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MyLocation } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import fr from 'date-fns/locale/fr';
import berlineImg from '../berline.jpeg'; // Adjust the path as necessary
import minivanImg from '../minivan.jpeg';
import greennImg from '../green.jpeg';
import ContactImage from "../tarif.jpeg";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const useStyles = makeStyles((theme) => ({
    success: {
        backgroundColor: '#4caf50',
    },
    error: {
        backgroundColor: '#f44336',
    },
    iconButton: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center', // Centrer horizontalement
        marginTop: theme.spacing(2),
    },
    icon: {
        fontSize: '1.5rem',
    },
    datePickerContainer: {
        display: 'inline-block',
        width: 'auto',
    },
    datePicker: {
        padding: '10px 14px',
        fontSize: '1rem',
        width: 'auto',
        maxWidth: '90px', // Adjust the width as needed
    },
    datePicker2: {
        padding: '10px 14px',
        fontSize: '1rem',
        width: 'auto',
        maxWidth: '60px', // Adjust the width as needed
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
    },
    image: {
        maxWidth: '100%',
        height: '200px',
    },
    selectFormControl: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    typography: {
        fontSize: '1.25rem',
    },
    vehicleSelection: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
        },
    },
    vehicleOption: {
        cursor: 'pointer',
        textAlign: 'center',
        border: '2px solid transparent',
        borderRadius: '8px',
        padding: '10px',
        '&.selected': {
            borderColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
    },
    vehicleImage: {
        width: '100px',
        height: 'auto',
        [theme.breakpoints.up('sm')]: {
            width: '150px',
        },
    },
    container: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            maxWidth: '600px',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '800px',
        },
    },
    availabilityMessage: {
        marginTop: theme.spacing(1),
        color: theme.palette.text.secondary,
        fontSize: '0.875rem',
        textAlign: 'center',
    },
}));

export const Tarifs = () => {
    const classes = useStyles();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [origin, setOrigin] = useState(null);
    const [destination, setDestination] = useState(null);
    const [distance, setDistance] = useState(null);
    const [originGeolocating, setOriginGeolocating] = useState(false);
    const [destinationGeolocating, setDestinationGeolocating] = useState(false);
    const [vehicleType, setVehicleType] = useState('berline');
    const [numPassengers, setNumPassengers] = useState(1);
    const [numBags, setNumBags] = useState(0);
    const navigate = useNavigate();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('success');

    const [departureDate, setDepartureDate] = useState(new Date());
    const [departureTime, setDepartureTime] = useState(new Date());

    useEffect(() => {
        const now = new Date();
        if (
            departureDate.getDate() === now.getDate() &&
            departureDate.getMonth() === now.getMonth() &&
            departureDate.getFullYear() === now.getFullYear()
        ) {
            const newTime = new Date();
            newTime.setMinutes(newTime.getMinutes() + 30);
            setDepartureTime(newTime);
        }
    }, [departureDate]);

    const calculateMinTime = () => {
        const today = new Date();
        if (
            departureDate.getDate() === today.getDate() &&
            departureDate.getMonth() === today.getMonth() &&
            departureDate.getFullYear() === today.getFullYear()
        ) {
            const minimumDate = new Date();
            minimumDate.setMinutes(minimumDate.getMinutes() + 30);
            return minimumDate;
        }
        return new Date(today.setHours(0, 0, 0, 0)); // Min time for future dates is midnight
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleOriginSelected = (place) => {
        if (!originGeolocating && place) {
            setOrigin(place);
        }
    };

    const handleDestinationSelected = (place) => {
        if (!destinationGeolocating && place) {
            setDestination(place);
        }
    };

    const geocodeAddress = async (address) => {
        try {
            if (!address) {
                throw new Error('Adresse invalide');
            }

            const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                params: {
                    q: address.formatted_address,
                    format: 'json',
                },
            });

            if (response.data.length > 0) {
                const location = response.data[0];
                return `${location.lat},${location.lon}`;
            } else {
                throw new Error('Adresse introuvable');
            }
        } catch (error) {
            console.error('Erreur lors de la géocodage de l\'adresse:', error.message);
            throw error;
        }
    };

    const calculateDistance = async () => {
        try {
            const originCoords = await geocodeAddress(origin);
            const destinationCoords = await geocodeAddress(destination);

            const isWeekendOrHoliday = checkWeekendOrHoliday(departureDate);
            const isNighttime = checkNighttime(departureTime);

            let costPerKilometer = 2.5;
            if (vehicleType === 'minivan') {
                costPerKilometer += 0.60;
            }
            if (isWeekendOrHoliday || isNighttime) {
                costPerKilometer += 0.5;
            }

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/directions`, {
                params: {
                    origin: originCoords,
                    destination: destinationCoords,
                }
            });

            const distanceInMeters = response.data.distance;
            const distanceInKilometers = distanceInMeters / 1000;

            const fixedCost = 10;
            const totalCost = (distanceInKilometers * costPerKilometer + fixedCost).toFixed(2);

            const durationInSeconds = response.data.duration;
            const durationInMinutes = Math.ceil(durationInSeconds / 60);

            setDistance({ kilometers: distanceInKilometers.toFixed(2), cost: totalCost, duration: durationInMinutes });
            drawRouteOnMap(originCoords, destinationCoords);

        } catch (error) {
            console.error('Erreur lors du calcul de la distance:', error.message);
            setDistance(null);
            setSnackbarMessage('Impossible de calculer la distance. Veuillez vérifier les adresses saisies.');
            setSnackbarColor('error');
            setSnackbarOpen(true);
        }
    };

    const checkWeekendOrHoliday = (date) => {
        const dayOfWeek = date.getDay();
        if (dayOfWeek === 0 || dayOfWeek === 6) {
            return true;
        }

        const holidays = {
            '01-01': 'Jour de l\'an',
            '05-01': 'Fête du Travail',
            '05-08': 'Armistice 1945',
            '07-14': 'Fête Nationale',
            '08-15': 'Assomption',
            '11-01': 'Toussaint',
            '11-11': 'Armistice 1918',
            '12-25': 'Noël',
        };

        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        if (formattedDate in holidays) {
            return true;
        }

        return false;
    };

    const checkNighttime = (time) => {
        const hours = time.getHours();
        if (hours >= 20 || hours < 6) {
            return true;
        }
        return false;
    };

    const options = {
        types: ['geocode', 'establishment'],
        componentRestrictions: { country: 'fr' },
    };

    const drawRouteOnMap = (originCoords, destinationCoords) => {
        if (window.google && originCoords && destinationCoords) {
            const directionsService = new window.google.maps.DirectionsService();
            const directionsRenderer = new window.google.maps.DirectionsRenderer();
            const map = new window.google.maps.Map(document.getElementById('map'), {
                zoom: 10,
                center: { lat: 47.2184, lng: -1.5536 },
            });
            directionsRenderer.setMap(map);

            const request = {
                origin: originCoords,
                destination: destinationCoords,
                travelMode: 'DRIVING',
            };

            directionsService.route(request, (result, status) => {
                if (status === 'OK') {
                    directionsRenderer.setDirections(result);
                } else {
                    console.error('Error fetching directions:', status);
                }
            });
        }
    };

    const handleReservation = () => {
        if (!origin || !destination|| !distance) {
            const url = `/Reserver`;
            navigate(url);
            return;
          /*  setSnackbarMessage('Veuillez sélectionner une adresse de départ et une adresse de destination.');
            setSnackbarColor('error');
            setSnackbarOpen(true);
            return;*/
        }

        if (!departureDate || !departureTime) {
            setSnackbarMessage('Veuillez sélectionner la date et l\'heure de départ.');
            setSnackbarColor('error');
            setSnackbarOpen(true);
            return;
        }

        if (origin.formatted_address === destination.formatted_address) {
            console.error('Adresse de départ et adresse de destination sont identiques.');
        }

        if (origin && destination) {
            const url = `/reserver?origin=${encodeURIComponent(origin.formatted_address)}&destination=${encodeURIComponent(destination.formatted_address)}&lieuDepart=${encodeURIComponent(origin.formatted_address)}&lieuArrivee=${encodeURIComponent(destination.formatted_address)}&dateDepart=${encodeURIComponent(departureDate.toISOString())}&heureDepart=${encodeURIComponent(departureTime.toISOString())}&vehicleType=${encodeURIComponent(vehicleType)}&numPassengers=${numPassengers}&numBags=${numBags}&distance=${encodeURIComponent(distance.kilometers)}&cost=${encodeURIComponent(distance.cost)}&duration=${encodeURIComponent(distance.duration)}`;
            navigate(url);
        } else {
            setSnackbarMessage('Veuillez sélectionner une adresse de départ et une adresse de destination.');
            setSnackbarColor('error');
            setSnackbarOpen(true);
        }
    };

    const handleGeolocate = (isOrigin) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                try {
                    const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
                    const address = response.data.display_name;
                    if (isOrigin) {
                        setOriginGeolocating(true);
                        setOrigin({ formatted_address: address });
                    } else {
                        setDestinationGeolocating(true);
                        setDestination({ formatted_address: address });
                    }
                } catch (error) {
                    console.error('Erreur lors de la géolocalisation:', error.message);
                    setSnackbarMessage('Erreur lors de la géolocalisation.');
                    setSnackbarColor('error');
                    setSnackbarOpen(true);
                }
            }, (error) => {
                console.error('Erreur lors de la récupération de la position:', error.message);
                setSnackbarMessage('Erreur lors de la récupération de votre position.');
                setSnackbarColor('error');
                setSnackbarOpen(true);
            });
        } else {
            console.error('La géolocalisation n\'est pas prise en charge par votre navigateur.');
            setSnackbarMessage('La géolocalisation n\'est pas prise en charge par votre navigateur.');
            setSnackbarColor('error');
            setSnackbarOpen(true);
        }
    };

    return (
        <>
            <Typography variant="h4" align="center" gutterBottom>
                Estimez votre trajet
            </Typography>
            <div className={classes.imageContainer}>
                <Link to="/">
                    <img
                        src={ContactImage}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </Link>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="outlined" className={classes.typography}>Adresse de prise en charge:</Typography>
                    <Box display="flex" alignItems="center">
                        <Autocomplete
                            apiKey={API_KEY}
                            style={{ width: '90%', fontSize: '1.2rem' }}
                            onPlaceSelected={handleOriginSelected}
                            types={['geocode']}
                            options={options}
                            defaultValue={origin ? origin.formatted_address : ''}
                            renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
                        />
                        <button
                            className={classes.iconButton}
                            onClick={() => handleGeolocate(true)}
                        >
                            <MyLocation className={classes.icon} />
                        </button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="outlined" className={classes.typography}>Adresse de destination:</Typography>
                    <Box display="flex" alignItems="center">
                        <Autocomplete
                            apiKey={API_KEY}
                            style={{ width: '90%', fontSize: '1.2rem' }}
                            onPlaceSelected={handleDestinationSelected}
                            types={['geocode']}
                            options={options}
                            defaultValue={destination ? destination.formatted_address : ''}
                            renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
                        />
                        <button
                            className={classes.iconButton}
                            onClick={() => handleGeolocate(false)}
                        >
                            <MyLocation className={classes.icon} />
                        </button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="outlined" className={classes.typography}>Prise en charge le : </Typography>
                    <Box className={classes.datePickerContainer}>
                        <DatePicker
                            selected={departureDate}
                            onChange={date => setDepartureDate(date)}
                            dateFormat="dd/MM/yyyy"
                            locale={fr}
                            minDate={new Date()}
                            className={classes.datePicker}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="outlined" className={classes.typography}>Prise en charge à : </Typography>
                    <Box className={classes.datePickerContainer}>
                        <DatePicker
                            selected={departureTime}
                            onChange={time => setDepartureTime(time)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Heure"
                            dateFormat="HH:mm"
                            locale={fr}
                            minTime={calculateMinTime()} // Restrict time selection based on date
                            maxTime={new Date().setHours(23, 59)} // Max time is 23:59
                            className={classes.datePicker2}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="outlined" className={classes.typography}>Nombre de passagers:</Typography>
                    <FormControl className={classes.selectFormControl}>
                        <Select
                            labelId="num-passengers-label"
                            style={{ width: '25%' }}
                            value={numPassengers}
                            onChange={(e) => setNumPassengers(e.target.value)}
                        >
                            {[...Array(10).keys()].map(n => (
                                <MenuItem key={n + 1} value={n + 1}>{n + 1}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="outlined" className={classes.typography}>Nombre de bagages:</Typography>
                    <FormControl className={classes.selectFormControl}>
                        <Select
                            labelId="num-bags-label"
                            value={numBags}
                            style={{ width: '25%' }}
                            onChange={(e) => setNumBags(e.target.value)}
                        >
                            {[...Array(10).keys()].map(n => (
                                <MenuItem key={n} value={n}>{n}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="outlined" className={classes.typography}>Type de véhicule:</Typography>
                    <Box className={classes.vehicleSelection}>
                        <Box className={`${classes.vehicleOption} ${vehicleType === 'berline' ? 'selected' : ''}`}
                             onClick={() => setVehicleType('berline')}>
                            <img src={berlineImg} alt="Berline" className={classes.vehicleImage} />
                            <Typography variant="body1">Berline</Typography>
                        </Box>
                        <Box className={`${classes.vehicleOption} ${vehicleType === 'green' ? 'selected' : ''}`}
                             onClick={() => setVehicleType('green')}>
                            <img src={greennImg} alt="Green" className={classes.vehicleImage} />
                            <Typography variant="body1">Électrique ou Hybride</Typography>
                            {vehicleType === 'green' && (
                                <Typography variant="body2" className={classes.availabilityMessage}>
                                    Sur demande et sous réserve de disponibilité
                                </Typography>
                            )}
                        </Box>
                        <Box className={`${classes.vehicleOption} ${vehicleType === 'minivan' ? 'selected' : ''}`}
                             onClick={() => setVehicleType('minivan')}>
                            <img src={minivanImg} alt="Minivan" className={classes.vehicleImage} />
                            <Typography variant="body1">Minivan</Typography>
                            {vehicleType === 'minivan' && (
                                <Typography variant="body2" className={classes.availabilityMessage}>
                                    Sur demande et sous réserve de disponibilité
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={calculateDistance}
                        disabled={!origin || !destination}
                        className={classes.button}
                    >
                        Calculer le coût
                    </Button>
                </Grid>
                {distance && (
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.typography}>
                            Distance: {distance.kilometers} km
                        </Typography>
                        <Typography variant="h6" className={classes.typography}>
                            Coût total: {distance.cost} €
                        </Typography>
                        <Typography variant="h6" className={classes.typography}>
                            Durée estimée: {distance.duration} minutes
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        onClick={handleReservation}
                        className="primary-button reserver"
                    >
                        Réserver
                    </Button>
                </Grid>
            </Grid>
            <div id="map" style={{ height: distance ? '400px' : '0px', marginTop: '20px' }}></div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <SnackbarContent
                    className={classes[snackbarColor]}
                    message={snackbarMessage}
                />
            </Snackbar>
        </>
    );
};

import React, {useLayoutEffect} from 'react';
import {Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import TransportScolaireImage from "../transportscolaire.png";
import ReservationButton from "../Components/ReservationButton";
import ExpandableContent from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '20px', // Ajoute de l'espace au-dessus du container principal
        marginBottom: '10px', // Ajoute de l'espace en dessous du container principal
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Centrage vertical
        marginBottom: '20px', // Ajoute de l'espace en dessous de l'image
    },
    image: {
        maxWidth: '100%', // Assurez-vous que l'image ne dépasse pas la largeur du conteneur
        height: 'auto', // Hauteur ajustée automatiquement pour maintenir le ratio
        maxHeight: '400px', // Hauteur maximale de l'image (à ajuster selon vos besoins)
    },
}));

export function TransportScolaire() {
    const classes = useStyles();
    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    return (
        <>
            <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>Transport Scolaire et loisirs
                de vos enfants</Typography>
            <div className={classes.imageContainer}>
                <Link to="/"> {/* Lien vers la racine du site */}
                    <img
                        src={TransportScolaireImage}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </Link>
            </div>
            <ExpandableContent>
                <Typography variant="body1" paragraph>
                    La sécurité de nos enfants est une préoccupation majeure pour nombre d’entre nous.
                    Au quotidien, en classe ou encore sur le trajet de l’école, il est important d’apporter
                    un cadre sécurisant aux plus jeunes. Du départ à l’école le matin au retour le soir
                    après les activités extrascolaires, confiez-nous le transport de vos enfants.
                </Typography>
                <Typography variant="body1" paragraph>
                    Desserte des écoles de l’agglomération nantaise
                    Sur la route et pour tous leurs déplacements, l’équipe {process.env.REACT_APP_BUSINESS_NAME} répond
                    à
                    une demande de transport scolaire privé de plus en plus croissante. Nous
                    desservons tous les établissements scolaires de Nantes, mais aussi les
                    établissements de l’agglomération. De la maternelle au lycée voir aux études
                    supérieures, nous assurons la sécurité et la ponctualité des jeunes.
                </Typography>
                <Typography variant="body1" paragraph>
                    Navette pour activités extrascolaires
                    Si vos enfants jouent dans un club de foot, de rugby ou de handball, il y a fort à
                    parier que des matchs vont être organisés dans les quatre coins du département.
                    Pour répondre à une demande de transport de groupe, nous mettons à disposition
                    de nos clients des VAN avec chauffeur permettant un seul véhicule par groupe de
                    6 en enfants et un adulte par exemple.
                </Typography>
                <Typography variant="body1" paragraph>
                    Vous avez une question ou besoin d’une information complémentaire sur notre
                    service de transport scolaire ? N’hésitez pas à nous appeler directement. Nous nous
                    ferons un plaisir de vous donner l’information que vous désirez et de programmer les
                    prochains transferts scolaires et/ou loisirs de votre enfant.
                </Typography>
            </ExpandableContent>
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </>
    );
}
